<template>
  <div>
    <div class="container pt-3" v-if="load && ebarimt!==null">
        <h5 class="text-center mb-5">TOKTOK Ebarimt</h5>
        <div class="row item">
            <div class="col-5">Захиалгын дугаар</div>
            <div class="col-7"><strong>{{ebarimt.tracking_data}}</strong></div>
        </div>
        <div class="row item">
            <div class="col-5">Огноо</div>
            <div class="col-7"><strong>{{ebarimt.date}}</strong></div>
        </div>
        <div class="row item" v-if="ebarimt.billType == 3">
            <div class="col-5">Байгууллага РД</div>
            <div class="col-7"><strong>{{ebarimt.customerNo}}</strong></div>
        </div>
        <div class="row item" v-if="ebarimt.billType == 3">
            <div class="col-5">Байгууллага Нэр</div>
            <div class="col-7"><strong>{{ebarimt.vat_cust_name}}</strong></div>
        </div>
        <div class="qr-image text-center mt-5">
            <img :src="'https://chart.googleapis.com/chart?cht=qr&chl=' + ebarimt.qr_data + '&choe=ISO-8859-1&chs=200&chld=L'" alt="">
        </div>
        <div class="row mt-5 item" style="border-top: 1px solid #cccccc;" v-if="ebarimt.bill_type == 1">
            <div class="col-5">Сугалааны дугаар</div>
            <div class="col-7">
                <div class="lottery">
                    <strong>{{ebarimt.lottery}}</strong>
                    <button class="btn btn-success ml-2" type="button" @click="copy"><i class="fas fa-copy"></i>copy</button>
                </div>
            </div>
        </div>
        <div class="row item">
            <div class="col-5">Нийт үнийн дүн</div>
            <div class="col-7"><strong>{{ebarimt.amount}}₮</strong> <button class="btn btn-success ml-2" type="button" @click="copy2"><i class="fas fa-copy"></i>copy</button></div>
        </div>
        <div class="row item">
            <div class="col-12">ДДТД: <strong>{{ebarimt.bill_id}}</strong> <button class="btn btn-success ml-2" type="button" @click="copy1"><i class="fas fa-copy"></i>copy</button></div>
            <!-- <div class="col-8"><strong>{{ebarimt.billId}}</strong></div> -->
        </div>
    </div>
    <div v-else>
      EBarimt харуулах боломжгүй
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import request from 'request'
export default {
  name: 'home',
  components: {
  },
  data () {
    return {
      load: false,
      ebarimt: null
    }
  },
  methods: {
    copy () {
      this.$clipboard(this.ebarimt.lottery.replace(' ', ''))
      this.$notify({
        group: 'foo',
        type: 'success',
        title: '',
        text: 'Хуулагдлаа.'
      })
    },
    copy1 () {
      this.$clipboard(this.ebarimt.bill_id)
      this.$notify({
        group: 'foo',
        type: 'success',
        title: '',
        text: 'Хуулагдлаа.'
      })
    },
    copy2 () {
      this.$clipboard(parseInt(this.ebarimt.amount))
      this.$notify({
        group: 'foo',
        type: 'success',
        title: '',
        text: 'Хуулагдлаа.'
      })
    }
  },
  created () {
    const self = this
    if (self.$route.query.order) {
      var options = {
        method: 'GET',
        url: 'https://api.toktokmall.mn/api/v1/getOrCreateEbarimtByTrackingNumber?tracking_number=' + self.$route.query.order,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      request(options, function (error, response) {
        if (error) throw new Error(error)
        const res = JSON.parse(response.body)
        if (res.status === 'success') {
          self.ebarimt = res.data
        } else {
          self.ebarimt = null
          self.$notify({
            group: 'foo',
            type: 'success',
            title: '',
            text: res.message
          })
        }
        self.load = true
      })
    } else {
      this.$notify({
        group: 'foo',
        type: 'success',
        title: '',
        text: 'Хуулагдлаа.'
      })
    }
  }
}
</script>
<style lang="scss">
  @import '../assets/main.css';
  body {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
  }
  .item {
    border-bottom: 1px solid #cccccc;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .btn {
    padding: 0;
    line-height: 26px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    float:right;
  }
  .btn .fas {
    margin-right: 5px;
  }
</style>
